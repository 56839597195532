const { SCHOOL_TYPES, GENDER } = require("./constants");

function getClassLevelLabel(schoolType) {
	return schoolType ?
		(schoolType === SCHOOL_TYPES.HIGH_SCHOOL ? 'Form' : 'Grade') : 'Level';
}

/**
 * 
 * @param {string} gender 
 */
function getPronouns(gender) {

	let possesive, object, subject;

	switch (gender) {
		case GENDER.MALE:

			possesive = 'his';
			object = 'him';
			subject = 'he';

			break;

		case GENDER.FEMALE:

			possesive = 'her';
			object = 'him';
			subject = 'she';

			break;
	
		default:

			possesive = 'their';
			object = 'them';
			subject = 'they';

			break;
	}

	return { possesive, object, subject }
}

function decodeJWT(token) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));

   return JSON.parse(jsonPayload);
}

module.exports = {
	decodeJWT,
	getClassLevelLabel,
	getPronouns,
}