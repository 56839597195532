import Quill from "quill";
import swal from "sweetalert";
import { GENDER } from "../../shared/constants";
import { MenuItem } from "@mui/material";

function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

function isEmail(email) {
	const re = /\S+@\S+\.\S+/;
	return re.test(email);
}


function requestConfirmation(question, {
	title="Confirm",
   confirmButtonCaption='Yes',
   cancelButtonCaption="Cancel",
}={}) {
   return swal({
		title,
		icon: "warning",
      text: question,
      buttons: [
         {
            text: confirmButtonCaption,
            value: true,
            className: "bg-red-600 text-white",
            visible: true,
         },
         {
            text: cancelButtonCaption,
            value: false,
            visible: true,
            className: "bg-white text-red-600 border-none"
         }
      ],
      closeOnClickOutside: false,
      closeOnEsc: false,
   });
}

function returnChangedAttributes(payload, org) {

   const res = {};
   
   for (let key in payload) {
      const value = payload[key];

      if (value !== org[key])
         res[key] = value;
   }

   return res;
   
}

/**
 * 
 * @param {string | HTMLElement} elem 
 * @returns {Quill}
 */
function createQuillInstance(elem) {

   const quill = new Quill(elem, {
      theme: 'snow',
      modules: {
         toolbar: [
            [{ header: [1, 2, 3, 4 , false ] }],
            [ 'bold', 'italic', 'underline' ],
            [ 'link', 'image', 'code-block' ],
         ]
      }
   });

   quill.getModule("toolbar").addHandler("image", () => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute('accept', 'image/*')

      input.click();
      
      input.onchange = () => {
         const file = input.files[0];
         
         if (file) {
            const reader = new FileReader();
        
            reader.addEventListener('load', function() {
               const range = quill.getSelection();
               quill.insertEmbed(range.index, "image", reader.result);
            });
        
            reader.readAsDataURL(file);
         }
      };
   });

   return quill;
}

/**
 * 
 * @param {Quill} quill 
 */
function isQuillEditorEmpty(quill) {
   const content = quill.getContents();
   return content.length() === 1;
}

function createGenderSelectItems() {
   return Object
      .entries(GENDER)
      .map(([key, value]) => {
         return <MenuItem key={key} value={value}>
            <span className="capitalize">{value}</span>
         </MenuItem>
      });
}

export {
   createGenderSelectItems,
   createQuillInstance,
	delay,
	isEmail,
   isQuillEditorEmpty,
	requestConfirmation,
   returnChangedAttributes,
};